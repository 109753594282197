<template lang="pug">
#brand-admin
  b-row
    b-col(cols="12")
      h2 Listado de Marcas y Modelos.
      p.text-secondary En esta sección prodras administrar todas las marcas y modelos de vehiculos y muchas otras cosas más.
    b-col(md="6").my-1.bg-white.mb-5
      b-row
        b-col
          h4.pt-4 Marcas
        b-col
          b-form-group.mt-4.mb-0
            b-form-input(
              v-model="filterBrand"
              type="search"
              id="filterInput"
              placeholder="Buscar Marca"
            )
        b-col(cols="12")
          b-button(variant="outline-okcarpremium" type="button" block).my-2
            font-awesome-icon(:icon="['fas', 'plus']")
            |  Nueva marca
      b-pagination(
        v-model="currentPageBrand"
        :total-rows="totalRowsBrand"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      )
      b-table(
        :items="brands"
        :fields="fieldsBrand"
        :current-page="currentPageBrand"
        :busy="isBusyBrand"
        :per-page="perPage"
        @filtered="onFilteredBrand"
        :filter="filterBrand"
        :filterIncludedFields="filterOn"
        :sort-direction="sortDirection"
        empty-text="No hay registros para mostrar"
        show-empty
        hover
        small
        striped
        responsive
      ).mt-1
        template(v-slot:table-busy)
          .text-center.text-okcarpremium.my-2
            b-spinner.align-middle
            strong  Cargando...
        template(v-slot:cell(id)="data") {{ data.item.id }}
        template(v-slot:cell(name)="data") {{ data.item.name }}
        template(v-slot:cell(action)="data")
          button(type='button' size="sm" @click="setBrandID(data.item.id)").btn.btn-outline-primary Modelos
    b-col(md="6").my-1.bg-white.mb-5
      b-row
        b-col
          h4.pt-4 Modelos
        b-col
          b-form-group.mt-4.mb-0
            b-form-input(
              v-model="filterModel"
              type="search"
              id="filterInput"
              placeholder="Buscar Modelo"
            )
        b-col(cols="12")
          b-button(variant="outline-okcarpremium" type="button" block).my-2
            font-awesome-icon(:icon="['fas', 'plus']")
            |  Nuevo Modelo
      b-pagination(
        v-model="currentPageModel"
        :total-rows="totalRowsModel"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      )
      b-table(
        :items="models"
        :fields="fieldsModel"
        :current-page="currentPageModel"
        :busy="isBusyModel"
        :per-page="perPage"
        @filtered="onFilteredModel"
        :filter="filterModel"
        :filterIncludedFields="filterOnModel"
        :sort-direction="sortDirectionModel"
        empty-text="No hay registros para mostrar"
        show-empty
        hover
        small
        striped
        responsive
      )
        template(v-slot:table-busy)
          .text-center.text-okcarpremium.my-2
            b-spinner.align-middle
            strong  Cargando...
        template(v-slot:cell(id)="data") {{ data.item.id }}
        template(v-slot:cell(name)="data") {{ data.item.name }}
        template(v-slot:cell(action)="data")
          button(type='button' size="sm").btn.btn-outline-primary Editar
</template>
<script>

export default {
  data() {
    return {
      fieldsBrand: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Nombre' },
        'action'
      ],
      fieldsModel: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Nombre' },
        'action'
      ],
      isBusyBrand: true,
      isBusyModel: true,
      brand_id: 1,
      model_id: 0,
      brands: [],
      models: [],
      baseURL: '',
      totalRowsBrand: 1,
      currentPageBrand: 1,
      totalRowsModel: 1,
      currentPageModel: 1,
      perPage: 10,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      sortDirectionModel: 'asc',
      filterBrand: null,
      filterModel: null,
      filterOn: [],
      filterOnModel: [],
    }
  },
  computed: {
    sortOptionsBrand() {
      return this.fieldsBrand
        .filter(filterBrand => filterBrand.sortable)
        .map(filterBrand => {
          return { text: filterBrand.label, value: filterBrand.key }
        })
    },
    sortOptionsModel() {
      return this.fieldsModel
        .filter(filterModel => filterModel.sortable)
        .map(filterModel => {
          return { text: filterModel.label, value: filterModel.key }
        })
    }
  },
  created() {
    this.baseURL = (process.env.NODE_ENV === "development") ? process.env.VUE_APP_API_DEVELOMENT : process.env.VUE_APP_API_PRODUCTION;
    this.getBrand();
  },
  methods: {
    setBrandID(brand_id) {
      this.brand_id = brand_id;
      this.getModel();
    },
    getBrand() {
      fetch(`${this.baseURL}/api/v1/brand`)
        .then(response => response.json())
        .then(data => {
          this.brands = data
          this.isBusyBrand = false
          this.totalRowsBrand = this.brands.length
        })
        .catch(error => {
          console.error(error)
          this.isBusyBrand = false
        })
    },
    onFilteredBrand(filteredItemsBrand) {
      this.totalRowsBrand = filteredItemsBrand.length
      this.currentPage = 1
    },
    onFilteredModel(filteredItemsModel) {
      this.totalRowsModel = filteredItemsModel.length
      this.currentPageModel = 1
    },
    getModel(){
      fetch(`${this.baseURL}/api/v1/model/${this.brand_id}`)
        .then(response => response.json())
        .then(data => {
          this.models = data
          this.isBusyModel = false
          this.totalRowsModel = this.brands.length
        })
        .catch(error => {
          console.error(error)
          this.isBusyModel = false
        })
      }
  },
  watch: {
    brands: function(){
      this.getModel(this.brand_id);
    },
  }
}
</script>
<style lang="scss">
  table td, .table th {
    vertical-align: middle !important;
  }
</style>